import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt-interceptor.service';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaymentService, CLIENT_ID } from 'src/app/services/payment/payment.service';
import { Camera } from '@ionic-native/camera/ngx';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

// ReactiveForms
import { ReactiveFormsModule } from '@angular/forms';

// NgCharts
import { NgChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Google Places
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from "@googlemaps/js-api-loader";

// Import the library
import { NgxStripeModule } from 'ngx-stripe';

// Define your client ID here
const CLIENT_ID_VALUE = '449f8516-791a-49ab-a09d-50f79a0678b6';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule, 
        BrowserAnimationsModule, 
        IonicModule.forRoot({ mode: 'ios' }),
        NgxStripeModule.forRoot(),
        ReactiveFormsModule, 
        AppRoutingModule,
        HttpClientModule,
        NgChartsModule,
        NgxGpAutocompleteModule,
        NgbModule,
        SocialLoginModule
    ],
    providers: [
        { 
            provide: HTTP_INTERCEPTORS, 
            useClass: JwtInterceptor, 
            multi: true 
        },
        { 
            provide: RouteReuseStrategy, 
            useClass: IonicRouteStrategy 
        }, 
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: 'AIzaSyB-YXDgS3i2hudg28znk84mzga8T-IDMvc',
                libraries: ['places']
            })
        },
        PaymentService,
        Camera,
        { 
            provide: CLIENT_ID, 
            useValue: CLIENT_ID_VALUE 
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              providers: [
                {
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider("Google-OAuth-Client-Id")
                },
                {
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider("Facebook-App-Id")
                }
              ]
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

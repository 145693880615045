import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import { Observable } from 'rxjs';
import { PaymentIntent } from '@stripe/stripe-js';
import { Payment } from 'src/app/models/payment';
import { CardResponse } from 'src/app/models/card-response';
import { of } from 'rxjs';

export const CLIENT_ID = new InjectionToken<string>('[CLIENT_ID]');

export const STRIPE_PUBLIC_KEY =
  'pk_test_51Ii5RpH2XTJohkGafOSn3aoFFDjfCE4G9jmW48Byd8OS0u2707YHusT5PojHOwWAys9HbvNylw7qDk0KkMZomdG600TJYNYj20';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  allowNavigation: boolean = false;

  constructor(
    @Inject(CLIENT_ID) private readonly clientId: string,
    private dataService: DataService,
  ) {}

  createPaymentIntent(params: any, clientId: string): Observable<PaymentIntent> {
    const headers = { merchant: clientId }; // Custom header
    const endpoint = 'payments/create-payment-intent';
    return this.dataService.post<PaymentIntent>(endpoint, params, headers);
  }

  // New method to return all cards (mock response)
  async getAllCards(customerId: string): Promise<Observable<CardResponse>> {
    // Mock response data
    const endpoint = `/v1/customers/${customerId}/cards`;
    const mockResponse = {
      "object": "list",
      "url": `/v1/customers/${customerId}/cards`,
      "has_more": false,
      "data": [
        {
          "id": "card_1MvoiELkdIwHu7ixOeFGbN9D",
          "object": "card",
          "address_city": null,
          "address_country": null,
          "address_line1": null,
          "address_line1_check": null,
          "address_line2": null,
          "address_state": null,
          "address_zip": null,
          "address_zip_check": null,
          "brand": "Visa",
          "country": "US",
          "customer": customerId,
          "cvc_check": null,
          "dynamic_last4": null,
          "exp_month": 4,
          "exp_year": 2024,
          "fingerprint": "mToisGZ01V71BCos",
          "funding": "credit",
          "last4": "4242",
          "metadata": {},
          "name": null,
          "tokenization_method": null,
          "wallet": null
        },
        {
          "id": "card_2MvotELkdIwHu7ixOeFGbN9E",
          "object": "card",
          "address_city": null,
          "address_country": null,
          "address_line1": null,
          "address_line1_check": null,
          "address_line2": null,
          "address_state": null,
          "address_zip": null,
          "address_zip_check": null,
          "brand": "MasterCard",
          "country": "US",
          "customer": customerId,
          "cvc_check": null,
          "dynamic_last4": null,
          "exp_month": 5,
          "exp_year": 2025,
          "fingerprint": "nUoisHZ01V72CDos",
          "funding": "debit",
          "last4": "1234",
          "metadata": {},
          "name": null,
          "tokenization_method": null,
          "wallet": null
        }
      ]
    };

    // Return the mock response as an Observable
    return of(mockResponse);
  }

  // New method to return all cards (mock response)
  async getPaymentsByCard(cardId: string): Promise<Observable<Payment[]>> {
    // Mock response data
    const mockResponse = [
      {
        "id": "1",
        "payment_date": "01/01/2024 10:30:00 AM",
        "payment_amount": 1000,
        "payment_status": "Successful",
        "card": {
            "id": "card_1MvoiELkdIwHu7ixOeFGbN9D",
            "object": "card",
            "address_city": null,
            "address_country": null,
            "address_line1": null,
            "address_line1_check": null,
            "address_line2": null,
            "address_state": null,
            "address_zip": null,
            "address_zip_check": null,
            "brand": "Visa",
            "country": "US",
            "customer": 'cus_NhD8HD2bY8dP3V',
            "cvc_check": null,
            "dynamic_last4": null,
            "exp_month": 4,
            "exp_year": 2024,
            "fingerprint": "mToisGZ01V71BCos",
            "funding": "credit",
            "last4": "4242",
            "metadata": {},
            "name": null,
            "tokenization_method": null,
            "wallet": null
          }
      },
      {
        "id": "2",
        "payment_date": "02/01/2024 10:30:00 AM",
        "payment_amount": 1000,
        "payment_status": "Successful",
        "card": {
            "id": "card_1MvoiELkdIwHu7ixOeFGbN9D",
            "object": "card",
            "address_city": null,
            "address_country": null,
            "address_line1": null,
            "address_line1_check": null,
            "address_line2": null,
            "address_state": null,
            "address_zip": null,
            "address_zip_check": null,
            "brand": "Visa",
            "country": "US",
            "customer": 'cus_NhD8HD2bY8dP3V',
            "cvc_check": null,
            "dynamic_last4": null,
            "exp_month": 4,
            "exp_year": 2024,
            "fingerprint": "mToisGZ01V71BCos",
            "funding": "credit",
            "last4": "4242",
            "metadata": {},
            "name": null,
            "tokenization_method": null,
            "wallet": null
          }
      },
      {
        "id": "3",
        "payment_date": "03/01/2024 10:30:00 AM",
        "payment_amount": 1000,
        "payment_status": "Successful",
        "card": {
            "id": "card_1MvoiELkdIwHu7ixOeFGbN9D",
            "object": "card",
            "address_city": null,
            "address_country": null,
            "address_line1": null,
            "address_line1_check": null,
            "address_line2": null,
            "address_state": null,
            "address_zip": null,
            "address_zip_check": null,
            "brand": "Visa",
            "country": "US",
            "customer": 'cus_NhD8HD2bY8dP3V',
            "cvc_check": null,
            "dynamic_last4": null,
            "exp_month": 4,
            "exp_year": 2024,
            "fingerprint": "mToisGZ01V71BCos",
            "funding": "credit",
            "last4": "4242",
            "metadata": {},
            "name": null,
            "tokenization_method": null,
            "wallet": null
          }
      }
    ];

    // Return the mock response as an Observable
    return of(mockResponse);
  }

}
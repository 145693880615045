import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SocialUser } from 'angularx-social-login';
import { DataService } from 'src/app/services/data/data.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { SocialAuthService } from "angularx-social-login";
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = 'auth/'; // Update this with your API base U
  
  constructor(
    private router: Router,
    private dataService: DataService,
    private toastService: ToastService,
    private authService: SocialAuthService
  ) { }

  isAuthenticated(): Observable<boolean> {
    const authToken = localStorage.getItem('authToken');
    const isAuthenticated = !!authToken; // Convert to boolean
    return of(isAuthenticated);
  }

  // Sign In
  async signIn(email: string, password: string): Promise<Observable<any>> {
    const submitEndpoint = this.apiUrl + 'token/'     
    const response = await this.dataService.post<any>(submitEndpoint, { email, password });
    response.subscribe(
      async (res) => {
        console.log(res);
        if (res.success) {
          // Handle success
          this.toastService.presentToast('Success!', res.message, 'top', 'success', 2000);
        } else {
          this.toastService.presentToast('Error', res.message, 'top', 'danger', 2000);
        }
      },
      error => {
        // Handle error
        console.error('Error:', error);
        this.toastService.presentToast('Error', error, 'top', 'danger', 2000);
      }
    );
    
    
    // Mock authentication success
    const isAuthenticated = this.validateLoginInfo(email, password);

    if (isAuthenticated) {
      // Return a mock response indicating successful authentication
      return of({
        success: true,
        message: 'Authentication successful. 6 digit 2FA code sent via SMS.'
      });
    } else {
      // Return a mock response with a failure message
      return of({
        success: false,
        message: 'Invalid email and/or password.'
      });
    }
  }

  // Sign In Social (Google, Facebook, Apple)
  async signInSocial(user: SocialUser): Promise<Observable<any>> {    
    // Simulate backend authentication
    // Replace this with actual backend API calls in production
    
    // Return a mock response indicating successful authentication
    return of({
      success: true,
      message: 'Authentication with ' + user.provider + ' successful. 6 digit 2FA code sent via SMS.'
    });
  }

  // Submit TwoFactor
  async submitTwoFactor(twoFactorCode: string): Promise<Observable<any>> {
    // Simulate backend authentication
    // Replace this with actual backend API calls in production
    
    // Mock authentication success
    const isAuthenticated = this.validateTwoFactor(twoFactorCode);
    
    if (isAuthenticated) {
      // Simulate a successful login with a mock response
      const token = this.generateAuthToken();
      localStorage.setItem('authToken', token);
      return of({
        success: true,
        message: 'Login successful.',
        token: token
      });
    } else {
      return of({
        success: false,
        message: 'Incorrect code entered.',
        token: null
      });
    }
  }

  async resendTwoFactor(email: string): Promise<Observable<any>> {
    // Simulate backend logic to resend the 2FA code
    // Replace this with actual backend API calls in production
    
    // Mock success response
    return of({
      success: true,
      message: 'A new code has been sent to you via SMS.'
    });
  }

  async resetPasswordRequest(email: string): Promise<Observable<any>> {
    // Simulate backend logic to reset the password
    // Replace this with actual backend API calls in production
    
    // Mock success response
    return of({
      success: true,
      message: 'Password reset instructions have been sent to your email.'
    });
  }

  async resetPassword(email: string, token: string, password: string, newPassword: string): Promise<Observable<any>> {
    // Simulate backend logic to reset the password
    // Replace this with actual backend API calls in production
    
    // Mock success response
    return of({
      success: true,
      message: 'Password reset successful!'
    });
  }

  // Sign Up Social (Google, Facebook, Apple)
  async signUpSocial(user: SocialUser): Promise<Observable<any>> {
    // Simulate backend authentication
    // Replace this with actual backend API calls in production
    
    // Return a mock response indicating successful authentication
    return of({
      success: true,
      message: 'Sign up with ' + user.provider + ' successful. 6 digit 2FA code sent via SMS.'
    });
  }

  // Sign out
  async signOut() {
    const submitEndpoint = this.apiUrl + 'logout/';
    try {
      const response = await this.dataService.put<any>(submitEndpoint, { }).toPromise();
      if (response.status === 200) {
        // Social sign out, put this back in production
        //this.authService.signOut();
        
        // Remove the token from localStorage upon logout
        localStorage.removeItem('authToken');
        // Navigate to sign-in
        this.router.navigateByUrl('/signin');
        this.toastService.presentToast('Success!', response.message, 'top', 'success', 2000);
      } else {
        console.error('Logout failed:', response.statusText); // Log failure
        this.toastService.presentToast('Error', response.message, 'top', 'danger', 2000);
      }
    } catch (error) {
      console.error('Error logging out:', error);
      this.toastService.presentToast('Error', error, 'top', 'danger', 2000);
    }
  }

  // For demo purposes, generate a random alphanumeric string as a mock auth token
  private generateAuthToken(): string {
    const tokenLength = 64; // Adjust token length as needed
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < tokenLength; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  }

  // Mock authentication function
  private validateLoginInfo(email: string, password: string): boolean {
    // In production this would involve sending a request to the backend
    // to verify the credentials against the user database
    // For demonstration purposes, let's assume the authentication is successful if the email and password are both 'demo'
    return email === 'john.doe@example.com' && password === 'CoolPass2024!';
  }

  // Mock authentication function
  private validateTwoFactor(code: string): boolean {
    // In production this would involve sending a request to the backend
    // to verify the code against the database
    // For demonstration purposes, let's assume the authentication is successful if the code is '555555'
    return code === '555555';
  }
}
